// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails');
console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails');
const modules = import.meta.globEager('../js/**/*.js');
// const styles = import.meta.glob('~/styles/**/*.scss');
// for (const path in styles) {
//   const splitPath = path.split('/');
//   const fileName = splitPath[splitPath.length - 1];
//   if (fileName[0] === '_') continue;
//   styles[path]();
// }

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>


// Example: Load Rails libraries in Vite.

import * as Turbo from '@hotwired/turbo';
Turbo.session.drive = false
Turbo.start();

// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()

// Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
